@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --primary-blue: #395664;
        --primary-white: #F7F6EF;
        --secondary-orange: #CB7752;
    }

.heroImg {
    height: 90vh;
    background: url(./assets/images/hero.jpg) no-repeat center/
cover;
    }
}